.privacy-policy {
    padding: 50px 0;
}

.privacy-policy * {
    color: #fff;
    font-family: "Satoshi";
}

.privacy-policy h3 {
    margin-top: 40px;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 1.6rem;
}

.privacy-policy ul li span {
    font-weight: 600;
}