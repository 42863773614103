.dashboard {
    font-family: 'Satoshi';
    background-color: #edf6ff;
}

.dash-left-container {
    width: 20%;
}
.dash-right-container {
    width: 80%;
}

.dash-sidebar {
    height: 100vh;
    position: sticky;
    top: 0;
  }
  
  .sidebar {
    width: 100%;
    height: 100%;
    background-color: #fff;
    // background-image: linear-gradient(to bottom, #05192f, #000);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding: 20px 10px;
    border-radius: 20px;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
  }
  
  .sidebar::-webkit-scrollbar {
    display: none;
  }
  
  .sidebar-logo {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  
  .sidebar-logo img {
    height: 100%;
  }
  
  .sidebar-links {
    flex: 1;
  }
  
  .sidebar-links a {
    width: 100%;
    height: 40px;
    margin-top: 10px;
    background-color: transparent;
    display: flex;
    align-items: center;
    color: #000;
    // font-size: .9rem;
    transition: .3s;
    border-radius: 15px;
    padding: 0 10px;
    font-weight: 500;
  }
  
  .sidebar-links a i {
    // font-size: .9rem;
    margin-right: 10px;
    margin-bottom: -3.5px;
}

.sidebar-links a.active {
    background: linear-gradient(to right, #16375c, #337CCF);
    color: #fff;
}

.sidebar-links a.active i {
    color: #fff;
}

.dash-title {
    font-size: 2.3rem;
    font-weight: 600;
}

.dash-card {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    background-color: #fff;
}

.dash-card-heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.dash-card-title {
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: #1d3e54;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.title-back-link {
    color: #1d3e54;
    margin-bottom: -5px;
    margin-right: 10px;
}

.dash-card-heading-btn {
    height: 40px;
    width: fit-content;
    // border: 1px solid #fff;
    border: none;
    color: #fff !important;
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-radius: 30px;
    background: linear-gradient(to right, #337CCF, #16375c);
    font-family: 'Satoshi';
    transition: .5s;
    font-size: .9rem;
    margin-bottom: 10px;
}

.dash-card-heading-btn i {
    margin-right: 5px;
    font-size: .8rem;
    margin-bottom: -3px;
}

.dash-card-heading-btn:hover {
    border-radius: 3px;
}

.share-btn {
    height: 50px;
    border-radius: 10px;
}

.n-rounded-btn {
    height: 50px;
    width: 100%;
    border: none;
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    border-radius: 10px;
    background: linear-gradient(to right, #337CCF, #16375c);
    font-family: 'Satoshi';
    transition: .5s;
    font-size: .9rem;
    margin-bottom: 10px;
}

.n-rounded-btn i {
    margin-right: 10px;
    font-size: .8rem;
    margin-bottom: -4px;
}

.release-img {
    width: 100px;
    border-radius: 5px;
}

.platform-img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
}

.label-logo {
    width: 70px;
    border-radius: 5px;
}

.dash-table td {
    background: none;
    padding-top: 15px;
    padding-bottom: 15px;
}

.dash-table thead td {
    font-weight: 600;
    white-space: nowrap;
}

.dash-table td {
    min-width: 100px;
}

.wide-table td {
    font-size: .9rem;
    min-width: 70px;
}

.table-sort {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.table-sort i {
    margin-left: 5px;
    margin-bottom: -5px;
}

.table-item span {
    font-size: .8rem;
    color: gray;
}

.table-item a {
    color: #337CCF;
}

.release-status {
    height: 25px;
    width: fit-content;
    padding: 0 8px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    font-size: .8rem;
}

.release-status.s-green {
    color: green;
    background-color: #0080003b;
}
.release-status.s-blue {
    color: #377099;
    background-color: #69b3e759;
}
.release-status.s-red {
    color: red;
    background-color: #ff000044;
    padding-right: 3px;
}
.release-status.s-red.n-right {
    padding-right: 8px;
}
.release-status.s-orange {
    color: #b98d1f;
    background-color: #daa5204b;
}

.release-status-icon {
    width: fit-content;
    height: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .7rem;
    background-color: #ff0000a7;
    color: #fff;
    border: none;
    outline: none;
    margin-left: 10px;
    padding-bottom: 2px;
    white-space: nowrap;
}

.dash-table tbody tr:last-child td {
    border-bottom: none;
}

.table-responsive::-webkit-scrollbar {
    height: 7px;
}
.table-responsive::-webkit-scrollbar-thumb {
    background-color: #377099 !important;
    border-radius: 5px;
}
.table-responsive::-webkit-scrollbar-track {
    background-color: #69b3e759;
    border-radius: 5px;
}

@media (max-width: 1200px) {
    .dash-left-container {
        width: 25%;
    }
    .dash-right-container {
        width: 75%;
    }
}

@media (max-width: 991px) {
    .dash-left-container, .dash-right-container {
        width: 100%;
    }
    .dash-sidebar {
        max-height: 300px;
        position: initial;
    }
}

.dash-card-heading-right {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.dash-card-heading-right h6 {
    text-align: right;
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 10px !important;
}

.dash-card-heading-right h6 span {
    color: #4b95e9;
}

.dash-tabs {
    width: 100%;
    display: flex;
    overflow-x: scroll;
}

.dash-tabs::-webkit-scrollbar {
    display: none;
}

.dash-tab {
    height: 50px;
    color: #000;
    transition: .3s;
    padding: 0 20px;
    border-bottom: 1.5px solid transparent;
    cursor: pointer;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.dash-tab.active {
    border-bottom-color: #337CCF;
    color: #337CCF;
}

.dash-form-group label {
    display: block;
    color: #16375c;
    font-weight: 600;
    margin-bottom: 5px;
}

.dash-form-select {
    width: 100%;
    height: 50px;
    background-color: #e8f3ff;
    border: 1px solid #e8f3ff;
    border-radius: 10px;
    padding: 0 10px;
    cursor: pointer;
    outline: none;
    transition: .3s;
}

.dash-form-select:focus {
    border-color: #4b95e9;
}

.dash-form-link {
    width: 100%;
    height: 50px;
    background-color: #e8f3ff;
    border: 1px solid #e8f3ff;
    border-radius: 10px;
    padding: 0 10px;
    display: block;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    transition: .3s;
}

.dash-form-link:hover {
    background-color: #cce3fc;
}

.dash-form-link i {
    margin-bottom: -3px;
    margin-right: 10px;
}

.dash-form-input {
    width: 100%;
    height: 50px;
    background-color: #e8f3ff;
    border: 1px solid #e8f3ff;
    border-radius: 10px;
    padding: 0 15px;
    outline: none;
    transition: .3s;
}

.dash-form-input:focus {
    border-color: #4b95e9;
}

.dash-form-input.disabled {
    opacity: .5;
    cursor: not-allowed;
}

.dash-form-textarea {
    width: 100%;
    height: 100px;
    background-color: #e8f3ff;
    border: 1px solid #e8f3ff;
    border-radius: 10px;
    padding: 10px 15px;
    outline: none;
    transition: .3s;
}

.dash-form-textarea:focus {
    border-color: #4b95e9;
}

.dash-form-textarea.disabled {
    opacity: .5;
    cursor: not-allowed;
}

.dash-form-check input {
    box-shadow: none !important;
    cursor: pointer;
}

.dash-form-check input:checked {
    background-color: #4b95e9 !important;
    border-color: #4b95e9 !important;
}

.dash-form-check label {
    font-weight: 500;
    cursor: pointer;
}

.img-file-upload {
    display: flex;
    .dash-form-file {
        flex: 1;
        width: auto;
    }
    .dash-form-file.logo-uploader {
        flex: 1;
        height: 100px;
        padding: 30px 0;
    }
    .img-preview {
        // width: 50%;
        flex: 1;
        height: fit-content;
        margin-right: 10px;
        border-radius: 10px !important;
        overflow: hidden;
        position: relative;
        img {
            width: 100%;
            height: auto;
        }
        .remove-artwork {
            position: absolute;
            top: 5px;
            right: 5px;
            height: 30px;
            width: 30px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: .8rem;
            cursor: pointer;
            color: #fff;
            background-color: red;
        }
    }
}

.logo-preview {
    flex: none !important;
    width: 100px !important;
    height: fit-content !important;
    img {
        width: 100% !important;
        height: auto !important;

    }
}

.dash-form-file {
    width: 100%;
    height: fit-content;
    background-color: #e8f3ff;
    border: 1px dashed #4b95e9;
    border-radius: 10px;
    padding: 0 15px;
    outline: none;
    transition: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 600;
    color: #377099;
    position: relative;
    overflow: hidden;
}

.dash-form-file:hover {
    background-color: #cce3fc;
}

.dash-form-file i {
    margin-right: 10px;
    font-size: 1.3rem;
    margin-bottom: -2px;
}

.dash-form-file input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.height-equal {
    padding-top: 35%;
}

// .dash-form-file .height-equal {
//     width: 100%;
//     aspect-ratio: 1 / 1;
// }

.form-file-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 5px;
    background-color: #377099;
    transition: .3s;
}

.upload-requirements {
    font-size: .8rem;
    font-weight: 500;
    max-width: 50%;
    padding-left: 20px;
}

.upload-requirements li span {
    font-weight: 600 !important;
}

.dash-form-dropdown button {
    width: 100%;
    height: 50px;
    background-color: #e8f3ff;
    border: 1px solid #e8f3ff;
    border-radius: 10px;
    padding: 0 15px;
    outline: none;
    transition: .3s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
}

.dash-form-dropdown ul {
    padding-top: 4px;
    padding-bottom: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
}

.dash-form-dropdown li {
    width: 100%;
    padding: 1px 5px 1px 30px;
}

.dash-form-dropdown li input {
    box-shadow: none !important;
    cursor: pointer;
}

.dash-form-dropdown li input:checked {
    background-color: #4b95e9 !important;
    border-color: #4b95e9 !important;
}

.dash-form-dropdown li label {
    cursor: pointer;
}

.dash-form-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.dash-form-btn {
    height: 50px;
    width: fit-content;
    color: #fff !important;
    display: flex;
    align-items: center;
    padding: 0 25px;
    border-radius: 30px;
    background: linear-gradient(to right, #337CCF, #16375c);
    transition: .5s;
    font-size: 1rem;
    border: none;
}

.dash-form-btn:hover {
    border-radius: 3px;
}

.dash-form-btn i {
    margin-bottom: -3px;
}

.add-track-btn {
    height: 50px;
    width: fit-content;
    color: #000 !important;
    display: flex;
    align-items: center;
    padding: 0 25px;
    border-radius: 30px;
    background: linear-gradient(to right, #cce3fc, #79abe6);
    transition: .5s;
    font-size: 1rem;
    border: none;
    i {
        margin-right: 10px;
        margin-bottom: -3px;
        font-size: .8rem;
    }
}

.add-track-btn:hover {
    border-radius: 3px;
}

.dash-widget {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.dash-widget-left {
    flex: 1;
}

.dash-widget-left h1 {
    font-weight: 600;
    margin-bottom: 0;
    color: #1d3e54;
}

.dash-widget-left h5 {
    font-size: 1rem;
    margin-bottom: 0;
}

.dash-widget-right {
    width: 50px;
    height: 50px;
    background-color: #cce3fc;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    margin-left: 10px;

    i {
        color: #1d3e54;
        margin-bottom: -5px;
    }
}

.l-releases {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.l-release {
    margin-top: 10px;
    text-align: center;
    img {
        width: 80px;
        border-radius: 5px;
    }

    h6 {
        font-weight: 600;
        margin-top: 5px;
        color: #1d3e54;
    }
}

.l-release:not(:last-child) {
    margin-right: 10px;
}

.dash-form-heading {
    font-weight: 600;
}

.dash-form-dropdown-link {
    padding: 1px 5px 1px 7px !important;
}

.dash-form-dropdown-link a {
    color: #000;
    display: flex;
    align-items: center;
    i {
        margin-right: 10px;
        margin-bottom: -3px;
    }
}

.added-tracks {
    width: 100%;
}

.added-track {
    height: fit-content;
    width: 100%;
    font-size: .9rem;
    border-radius: 5px;
    background-color: #cce3fc;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    margin-top: 10px;
    font-weight: 600;
    i {
        margin-left: 10px;
        margin-bottom: -3px;
        font-size: .6rem;
        cursor: pointer;
    }
}

.added-track-right {
    flex: 1;
    padding-right: 10px;
    display: flex;
    align-items: center;
}

.drag-track-btn {
    font-size: 1rem !important;
    margin-left: 0 !important;
    margin-right: 10px;
    cursor: grab;
}

.added-track:not(:last-child) {
    margin-right: 10px;
}

.dash-form-dropdown li:not(.form-check) {
    cursor: pointer;
    padding-left: 10px;
    transition: .3s;
    i {
        margin-right: 10px;
        margin-top: 8px;
    }
}

.dash-form-dropdown li:not(.form-check):hover {
    background-color: #e8f3ff;
}

.selected-artists {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.selected-artist {
    height: fit-content;
    width: fit-content;
    font-size: .9rem;
    border-radius: 5px;
    background-color: #cce3fc;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    margin-top: 10px;
    i {
        margin-left: 15px;
        margin-bottom: -3px;
        font-size: .6rem;
        cursor: pointer;
    }
}

.selected-artist-left {
    flex: 1;
    h6 {
        margin-bottom: 0;
        font-weight: 600;
    }
}

.selected-artist:not(:last-child) {
    margin-right: 10px;
}

.dash-copy-row {
    display: flex;
}

.dash-copy-year {
    width: fit-content;
    margin-right: 10px;
}

.dash-copy-line {
    flex: 1;
    width: auto;
}

.release-details {
    display: flex;
    flex-wrap: wrap;
}

.release-details-img {
    width: 200px !important;
    height: 200px !important;
    object-fit: cover;
    border-radius: 10px;
    margin: 10px 0;
}

.release-details-items {
    flex: 1;
    margin: 10px 20px;
}

.release-details-item {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    h6 {
        margin-bottom: 0;
        font-weight: 600;
        white-space: nowrap;
    }
    span {
        text-align: right;
        font-size: .9rem;
        font-weight: 500;
        margin-left: 10px;
        word-wrap: break-word;
    }
}

@media (max-width: 800px) {
    .release-details-items {
        width: 100%;
        flex: auto;
        margin: 0;
    }
    .release-details-img {
        margin-bottom: 20px;
    }
}

.release-stores {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    a {
        display: block;
        margin-bottom: 10px;
        transition: .3s;
    }
    a:hover {
        transform: translateY(-2px);
    }
    img {
        height: 35px;
    }
}

.success-modal {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    .success-icon {
        font-size: 3.3rem;
    }
    h5 {
        margin-bottom: 0;
        margin-top: 15px;
        font-weight: 600;
    }
    .success-close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        color: #000;
    }
}

.table-action-btn {
    height: 30px;
    width: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
    cursor: pointer;
    border: none;
    outline: none;
    i {
        margin-bottom: -3px;
    }
}

.table-action-btn.a-blue {
    color: #377099;
    background-color: #69b3e759;
}
.table-action-btn.a-red {
    color: red;
    background-color: #ff000044;
}
.table-action-btn.a-green {
    color: green;
    background-color: #0080003b;
}

.modal-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
        margin-bottom: 0;
        font-weight: 600;
    }
    i {
        cursor: pointer;
    }
}


.dash-input {
    width: 300px;
    max-width: 100%;
    height: 50px;
    border: 1px solid transparent;
    transition: .5s;
    background-color: #cce3fc;
    border-radius: 15px;
    outline: none;
    padding: 0 15px;
    margin-bottom: 10px;
    color: #000;
    font-size: .9rem;
  }
  
  .dash-input::placeholder {
    color: gray;
  }
  
  .dash-input:focus {
    border-color: #337CCF;
  }

.table-pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: scroll;
    margin: 40px 0 20px 0;
}

.table-pagination::-webkit-scrollbar {
    display: none;
}

.table-pagination button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    color: #377099;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.table-pagination button.active {
    background-color: #cce3fc;
}

.table-pagination span {
    border: none;
    outline: none;
    background-color: transparent;
    color: #377099;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.table-btn {
    height: 35px;
    width: fit-content;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #377099;
    border: none;
    outline: none;
    background-color: #69b3e759;
    font-size: .9rem;
    padding: 0 10px;
}

.table-btn i {
    margin-bottom: -2px;
    margin-right: 5px;
}

.table-btn.a-blue {
    color: #377099;
    background-color: #69b3e759;
}
.table-btn.a-red {
    color: red;
    background-color: #ff000044;
}
.table-btn.a-green {
    color: green;
    background-color: #0080003b;
}

.top-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}

.top-item h6 {
    margin-bottom: 0;
    font-weight: 600;
}

.top-item:not(:last-child) {
    border-bottom: 1px solid #eee;
}

.sorting-card {
    height: fit-content !important;
    padding-bottom: 10px;
}

.top-item span {
    color: #377099;
    font-size: .9rem;
    font-weight: 500;
}

.payouts-widget-left {
    width: 100%;
}

.payouts-widget-info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    h6 {
        font-weight: 600;
        font-size: 1.2rem;
        margin-bottom: 0;
    }
    span {
        font-size: 1.2rem;
        color: #16375c;
        color: #377099;
    }
}

.payouts-widget-progress {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background-color: #69b3e759;
    margin-top: 30px;
}

.payout-method-card {
    color: #fff;
    background-image: linear-gradient(to bottom, #16375c, #377099);
    text-align: center;
    h5 {
        font-weight: 600;
        margin-bottom: 5px;
    }
    a {
        height: 40px;
        border: 1px solid #fff;
        padding: 0 20px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        margin: auto;
        color: #fff;
        font-size: .9rem;
        margin-top: 15px;
        transition: .3s;
        background-color: #16375c;
        border-color: #16375c;
    }
    a:hover {
        background-color: #69b3e759;
        border-color: #fff;
    }
    .payout-check {
        font-size: .9rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        i {
            margin-bottom: -2px;
            margin-right: 8px;
        }
    }
}

.toggle {
    height: 25px;
    width: 50px;
    border-radius: 20px;
    background-color: #7e22222f;
    border: 1px solid #7e2222;
    cursor: pointer;
    transition: .3s;
    padding: 0 2.5px;
    display: flex;
    align-items: center;
}

.toggle.active {
    background-color: #00800024;
    border: 1px solid #227e38;
}

.toggle div {
    height: 19px;
    width: 19px;
    border-radius: 50%;
    background-color: #7e2222;
    transition: .3s;
}

.toggle.active div {
    background-color: #227e38;
    transform: translateX(24px);
}

.admin-dash-form-file {
    width: 100%;
    height: fit-content;
    background-color: #e8f3ff;
    border: 1px dashed #4b95e9;
    border-radius: 10px;
    padding: 15px;
    outline: none;
    transition: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: #377099;
    position: relative;
}

.admin-dash-form-file:hover {
    background-color: #cce3fc;
}

.admin-dash-form-file i {
    margin-right: 10px;
    font-size: 1.2rem;
    margin-bottom: -2px;
}

.admin-dash-form-file input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.dash-form-input.share-input {
    width: 60px;
    // height: 40px;
    text-align: center;
}

.share-input[type="number"]::-webkit-inner-spin-button,
.share-input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.share-input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}

.cancel-btn {
    background-image: none;
    background: #16375c !important;
}

.dash-form-input.share-input[type="number"]::-webkit-inner-spin-button,
.dash-form-input.share-input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.dash-form-input.share-input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.track-split-text {
    margin-left: 5px;
    font-style: italic;
}

.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000000b8;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
    opacity: 0;
    transition: .3s;
}
.loader-container.active {
    opacity: 1;
    pointer-events: all;
}
.loader {
    width: 50px;
    aspect-ratio: 1;
    --_g: no-repeat radial-gradient(farthest-side,#cce3fc 94%,#0000);
    background:
      var(--_g) 0    0,
      var(--_g) 100% 0,
      var(--_g) 100% 100%,
      var(--_g) 0    100%;
    background-size: 40% 40%;
    animation: l38 .5s infinite; 
  }
  @keyframes l38 {
    100% {background-position: 100% 0,100% 100%,0 100%,0 0}
  }

.mapping-base {
    width: 100%;
    height: 50px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    background-color: #e0efff;
    border-radius: 10px;
    padding: 0 10px;
    font-weight: 600;
}

.error-item {
    width: 100%;
    height: fit-content;
    padding: 5px 10px;
    border-radius: 10px;
    color: #7e2222;
    background-color: #7e22222f;
    text-align: left;
}

.date-range-selector .dash-form-group {
    width: 150px;
    margin-left: 10px;
}


.teams-item {
    padding: 5px;
    transition: .3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.teams-item:hover {
    background-color: #eee;
}
.teams-item:not(:last-child) {
    border-bottom: 1px solid #eee;
}
.teams-item h5 {
    font-size: 1.1rem;
    margin-bottom: 2px !important;
    font-weight: 500;
    display: flex;
    align-items: flex-end;
    span {
        color: #000000b8;
        font-size: .9rem;
        margin: 0 5px;
    }
}
.teams-item h6 {
    color: #000000b8;
    font-size: .9rem;
}

.streams-filters {
    display: flex;
    flex-wrap: wrap;
}

.react-datepicker-wrapper {
    width: 100%;
}

.draft-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #eee;
    padding: 10px;
    border-radius: 10px;
}

.draft-card-actions {
    display: flex;
    align-items: center;
}

.draft-card h6 {
    margin-bottom: 0;
}

.draft-card a {
    width: fit-content;
}

.no-data {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 600;
}

.no-data-100 {
    height: 100px;
}

.disabled {
    opacity: .5 !important;
    cursor: not-allowed !important;
}

.upload-progress {
    width: 300px;
    max-width: 90%;
    height: 7px;
    border-radius: 5px;
    background-color: #cce3fc;
    margin-top: 40px;

    div {
        height: 100%;
        border-radius: 5px;
        background-color: #337CCF;
        transition: .3s;
    }
}

.maintenance-toggle-container {
    width: 300px;
    max-width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;
    box-shadow: 0 0 15px #00000026;
    padding: 10px;
    border-radius: 15px;

    h6 {
        flex: 1;
        margin-bottom: 0;
        font-size: 1.1rem;
        font-weight: 600;
    }
}

.maintenance-toggle {
    width: 60px;
    height: 30px;
    border-radius: 30px;
    border: 1px solid #bebebe;
    cursor: pointer;
    position: relative;

    div {
        position: absolute;
        top: 1px;
        left: 1px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: #bebebe;
        transition: .3s;
    }
}

.maintenance-toggle.active {
    border-color: #377099;

    div {
        background-color: #377099;
        transform: translateX(calc(60px - 100% - 4px));
    }
}

.maintenance-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;

    img {
        height: 150px;
    }

    h6 {
        font-size: 1.3rem;
        text-align: center;
        font-weight: 600;
    }
}